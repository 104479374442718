import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import OverviewGrid from 'components/Frequently_Asked_Question/1080p_Forbidden_Character/forbiddenCharacterTable';
export const _frontmatter = {
  "title": "FullHD Cameras Forbidden Characters",
  "path": "/Frequently_Asked_Question/1080p_Forbidden_Character/",
  "dateChanged": "2023-01-24",
  "author": "Mike Polinowski",
  "excerpt": "What special characters are allowed for the camera account username and password ?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - FullHD Cameras Forbidden Characters' dateChanged='2023-01-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='What special characters are allowed for the camera account username and password ?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/1080p_Forbidden_Character/' locationFR='/fr/Frequently_Asked_Question/1080p_Forbidden_Character/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "fullhd-cameras-forbidden-characters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#fullhd-cameras-forbidden-characters",
        "aria-label": "fullhd cameras forbidden characters permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FullHD Cameras Forbidden Characters`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: What special characters are allowed for the camera account username and password ?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The following character are allowed for username/password for an INSTAR FullHD Camera (newer WQHD 2k+ cameras support the entire, non-extended UTF-8 character set):`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`space ! # $ % ( ) * + - . / < ? @ [ ] ^ _ { | }`}</code></li>
    </ul>
    <p>{`From this list there are a number of characters that generally work but cause problems with individual services:`}</p>
    <OverviewGrid mdxType="OverviewGrid" />
    <EuiSpacer mdxType="EuiSpacer" />
    {/* 
     |    |            |          |      |      |       |          |
     | -- |      --    |    --    |  --  |  --  |   --  |     --   |
     | No |  Character |  HTTP(S) | RTSP | RTMP | ONVIF | P2P\* |
     | 1 | space (+) | OK | OK | NO\*\* | NO | NO |
     |   | enc. %20  | OK | OK | NO\*\* | NO | OK |
     | 2 | ! |OK | OK | OK | OK | OK |
     |   | enc. %21 | OK | OK | OK | NO | OK |
     | 4 | \# |OK | OK | OK | OK | OK |
     |   | enc. %23 | OK | OK | OK | NO | OK |
     | 5 | $ |OK | OK | OK | OK | OK |
     |   | enc. %24 | OK | OK | OK | NO | OK |
     | 6 | % |OK | OK | OK | OK | NO |
     |   | enc. %25 | OK | OK | OK | NO | OK |
     | 7 | \( |OK | OK | OK | OK | OK |
     |   | enc. %28 | OK | OK | OK | NO | OK |
     | 8 | \) |OK | OK | OK | OK | OK |
     |   | enc. %29 | OK | OK | OK | NO | OK |
     | 9 | \* |OK | OK | OK | OK | OK |
     |   | enc. %2A | OK | OK | OK | NO | OK |
     | 10 | \+ |OK | OK | NO\*\* | NO | NO |
     |    | enc. %2B | OK | OK | OK | NO | OK |
     | 11 | \- |OK | OK | OK | OK | OK |    
     | 12 | \. |OK | OK | OK | OK | OK |  
     | 13 | / |OK | OK | OK | OK | OK |
     |    | enc. %2F | OK | OK | OK | NO | OK |
     | 14 | \< |OK | OK | OK | OK | OK |
     |    | enc. %3C | OK | OK | OK | NO | OK |
     | 15 | ? |OK | OK | OK | OK | OK |
     |    | enc. %3F | OK | OK | OK | NO | OK |
     | 16 | @ |OK | OK | OK | OK | OK |
     |    | enc. %40 | OK | OK | OK | NO | OK |
     | 17 | [ |OK | OK | OK | OK | OK |
     |    | enc. %7B | OK | OK | OK | NO | OK |
     | 18 | ] |OK | OK | OK | OK | OK |
     |    | enc. %5D | OK | OK | OK | NO | OK |
     | 19 | ^ |OK | OK | OK | OK | OK |
     |    | enc. %5E | OK | OK | OK | NO | OK |
     | 20 | _ |OK | OK | OK | OK | OK |     
     | 21 | { |OK | OK | OK | OK | OK |
     |    | enc. %7B | OK | OK | OK | NO | OK |
     | 22 | \| |OK | OK | OK | OK | OK |
     |    | enc. %7C | OK | OK | OK | NO | OK |
     | 23 | } | OK | OK | OK | OK | OK |
     |    | enc. %7D | OK | OK | OK | NO | OK | */}
    <ul>
      <li parentName="ul">{`*`}{` P2P UID has to start with `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar-`}</code>{` (new version)`}</li>
      <li parentName="ul">{`*`}{`*`}{` Not able to login to the webUI (Safari)`}</li>
    </ul>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      